import { TypeApiRoute } from '../../../../models/type.api.request';

type TypeRequest = {
  id: string;
};
type TypeResponse = string;

export const deleteAssistanceFieldReportsAutoUpload: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (params: TypeRequest) => `/api/da-reports/reports/field/config/${params.id}`,
  method: 'DELETE',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
