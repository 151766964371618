import { has, merge, toNumber } from 'lodash';
import { makeAutoObservable, toJS } from 'mobx';

import { EAttributeUnit, IAttributeItem } from '../../api/models/attribute.model';
import { IDictionaryDependencyWithAliasName } from '../../api/models/dependendency.model';
import { IDictionaryExportOptions, TDictionaryItem } from '../../api/models/dictionaries.model';
import {
  EHasAdditionalAttribute,
  ESearchPath,
  IAdvancedFilter,
} from '../../types/advancedFilter.types';
import { DictionariesService } from '../services';
import { lazyInject, provide, TypeApiRequest } from '../shared/utils';
import { AdvancedFilterStore, TreeStore } from '../stores';
import { ISelectOptionExtended } from '../../types/selectOption';

import {
  DictionariesStore,
  EFormModule,
  TCurrentDictionaryExtended,
} from './../stores/dictionaries/dictionaries.store';

@provide.singleton()
export class DictionariesController {
  @lazyInject(DictionariesService)
  dictionariesService: DictionariesService;

  @lazyInject(DictionariesStore)
  dictionariesStore: DictionariesStore;

  @lazyInject(AdvancedFilterStore)
  advancedFilterStore: AdvancedFilterStore;

  @lazyInject(TreeStore)
  treeStore: TreeStore;

  constructor() {
    makeAutoObservable(this);
  }

  getAllDictionaries = () => {
    return this.dictionariesService.getAllDictionaries();
  };

  getShallowDictionaryListByRemoteName = (remoteName: string) => {
    return this.dictionariesService.getShallowDictionaryListByRemoteName(remoteName);
  };

  getDictionaryChildrenByParentId = (remoteName: string, parentId: string) => {
    return this.dictionariesService.getChildDictionaryListByParentId(remoteName, parentId);
  };

  getDictionaryItem = (remoteName: string, id: string) => {
    return this.dictionariesService.getDictionaryItem(remoteName, id);
  };

  getDictionaryItemBreadcrumbs = (remoteName: string, id: string) => {
    return this.dictionariesService
      .getDictionaryItemBreadcrumbs(remoteName, id)
      .then(dictionaryItemCrumbs => {
        this.dictionariesStore.setCurrentDictionaryItemBreadCrumbs(dictionaryItemCrumbs.crumbs);
        return dictionaryItemCrumbs;
      });
  };

  updateCurrentDictionaryItem = (dictionaryItem: Partial<TCurrentDictionaryExtended>) => {
    const previousItem = {
      ...toJS(this.dictionariesStore.currentDictionaryItem),
    };

    this.dictionariesStore.setCurrentDictionaryItem({ ...previousItem, ...dictionaryItem });
  };

  changeCurrentDictionaryHandler = (
    attributeValue: TDictionaryItem['attrs'],
    key: string,
    removeValue?: boolean
  ) => {
    const dictionaryItem = toJS(this.dictionariesStore.currentDictionaryItem);

    const updatedMap = dictionaryItem?.validAttributes || new Map();

    if (removeValue) {
      const attrList = { ...dictionaryItem.attrs };

      delete attrList[key];
      updatedMap.set(key, { ...updatedMap.get(key), value: null });

      this.updateCurrentDictionaryItem({
        attrs: attrList,
        validAttributes: updatedMap,
      });
      return;
    }

    updatedMap.set(key, { ...updatedMap.get(key), value: attributeValue[key] });

    this.updateCurrentDictionaryItem({
      validAttributes: updatedMap,
      attrs: { ...(dictionaryItem?.attrs || {}), ...attributeValue },
    });
  };

  getDictionaryItemListByNameOrId = (remoteName: string, nameOrId: string) => {
    return this.dictionariesService.getDictionaryItemListByNameOrId(remoteName, nameOrId);
  };

  getNewAttributeItemByAttributeId = (id: string) => {
    return this.dictionariesStore.getAttributeItemByAttributeId(id);
  };

  setNewAttributeItemByAttributeId = (id: string, newAttribute: IAttributeItem) => {
    this.dictionariesStore.setNewAttributeToNewAttributeMap(id, newAttribute);
  };

  removeNewAttributeItemFormAttributeList = (id: string) => {
    this.dictionariesStore.removeNewAttributeItemFormAttributeList(id);
  };

  changeNewAttributeItem = (id: string, newItem: IAttributeItem) => {
    this.dictionariesStore.changeNewAttributeItemFromAttributeList(id, newItem);
  };

  updateDictionaryData = (payload: TDictionaryItem, isReplace?: boolean) => {
    const modifiedValues = this.prepareEditDictionaryItemToSave(
      {
        ...payload,
        validAttributes: toJS(this.dictionariesStore.currentDictionaryItem).validAttributes,
        attrs: toJS(this.dictionariesStore.currentDictionaryItem).attrs,
      },
      isReplace
    );

    return this.dictionariesService.updateDictionaryItem(modifiedValues);
  };

  rewriteDictionaryData = (payload: TDictionaryItem, isReplace?: boolean) => {
    const modifiedValues = this.prepareEditDictionaryItemToSave(
      {
        ...payload,
        validAttributes: toJS(this.dictionariesStore.currentDictionaryItem).validAttributes,
        attrs: toJS(this.dictionariesStore.currentDictionaryItem).attrs,
      },
      isReplace
    );

    return this.dictionariesService.rewriteDictionaryItem(modifiedValues);
  };

  activateDictionaryData = (data: TDictionaryItem) => {
    const payload = {
      entityId: data.id,
      version: data.version,
      remoteName: this.dictionariesStore.selectedDictionaryListRemoteName.remoteName,
    };

    return this.dictionariesService.activateDictionaryItem(payload);
  };

  archiveDictionaryItem = (data: TDictionaryItem) => {
    const payload = {
      remoteName: this.dictionariesStore.selectedDictionaryListRemoteName.remoteName,
      id: data.id,
    };

    return this.dictionariesService.archiveDictionaryItem(payload);
  };

  deleteDictionaryItem = (data: TDictionaryItem) => {
    const payload = {
      remoteName: this.dictionariesStore.selectedDictionaryListRemoteName.remoteName,
      entityId: data.id,
    };

    return this.dictionariesService.deleteDictionaryItem(payload);
  };

  createDictionaryItem = (data: TypeApiRequest<'createDictionaryItem'>) => {
    const payload = {
      remoteName: this.dictionariesStore.selectedDictionaryListRemoteName.remoteName,
      ...data,
    };

    const modifiedValues = this.prepareEditDictionaryItemToSave(
      {
        ...payload,
        validAttributes: toJS(this.dictionariesStore.currentDictionaryItem)?.validAttributes,
        attrs: toJS(this.dictionariesStore.currentDictionaryItem)?.attrs,
      },
      true
    );

    return this.dictionariesService.createDictionaryItem(modifiedValues);
  };

  changeFormModule = (moduleType: EFormModule) => {
    this.dictionariesStore.setFormModule(moduleType);
  };

  setParentId = (data: TDictionaryItem | string) => {
    if (typeof data === 'string') {
      this.dictionariesStore.setParentId(data);
    } else {
      this.dictionariesStore.setParentId(data?.id || null);
    }
  };

  prepareEditDictionaryItemToSave = (
    data: Partial<TCurrentDictionaryExtended & { remoteName: string }>,
    isReplace?: boolean
  ) => {
    if (data?.isChild === false) {
      delete data.parentId;
    } else {
      delete data.isChild;
    }

    if (data?.isDouble === false) {
      delete data.originalLink;
    } else {
      delete data.isDouble;
    }

    // TODO убрать при доработке отправки атрибутов
    delete data.picLink;

    const modifiedValues: any = {
      remoteName:
        this.dictionariesStore.selectedDictionaryListRemoteName?.remoteName || data?.remoteName,
      id: this.dictionariesStore.currentDictionaryItem?.id || null,
    };

    if (data?.attrs) {
      Object.entries(data.attrs).forEach(([key, value]) => {
        const attribute = data.validAttributes.get(key);

        if (attribute) {
          if (attribute.units === EAttributeUnit.Double) {
            data.attrs[key] = parseFloat(String(value).replace(',', '.'));
            return;
          }

          if (attribute.units === EAttributeUnit.Integer) {
            data.attrs[key] = parseInt(value, 10);
            return;
          }

          data.attrs[key] = value;
        }
      });

      if (data?.invalidAttribute) {
        Object.entries(data.invalidAttribute).forEach(([key]) => {
          if (has(data.attrs, key)) {
            delete data.attrs[key];
          }
        });
      }
    }

    if (isReplace) {
      delete data.version;
      delete data.editDate;

      if (!data?.description) {
        modifiedValues.description = '';
      }

      if (!data?.authorFullName) {
        modifiedValues.authorFullName = '';
      }

      Object.entries(data).forEach(([key, value]) => {
        modifiedValues[key] = value;
      });
    } else {
      Object.entries(data).forEach(entry => {
        const [key, value] = entry;

        if (value !== this.dictionariesStore.currentDictionaryItem[key]) {
          modifiedValues[key] = value;
        }
      });
    }

    return modifiedValues;
  };

  getAttributeList = (remoteName: string): Promise<ISelectOptionExtended<IAttributeItem>[]> => {
    return this.dictionariesService.fetchDictionaryAttributeList(remoteName).then(resp => {
      return resp.content.map(item => ({ label: item.name, value: item.code, rawData: item }));
    });
  };

  private _getDictionaryFilterAdditionalAttributeValue = (
    value: any,
    type: EAttributeUnit
  ): string | number | boolean => {
    switch (type) {
      case EAttributeUnit.Boolean:
        return value === 'true';

      case EAttributeUnit.Double:
      case EAttributeUnit.Integer:
        return Number(value);

      default:
        return String(value);
    }
  };

  fetchDictionariesItemsByFilter = async (remoteName: string, payload: IAdvancedFilter) => {
    this.advancedFilterStore.setIsLoading(true);

    const mappedData: TypeApiRequest<'getAllDictionariesByParams'> = {
      remoteName,
      parentId: payload?.parentId,
      nameFilter: payload?.name,
      codeFilter: payload?.code,
      latestVersion: payload?.isLastVersion,
      originalOnly: payload?.isOriginal,
      level: payload?.level,
      status: payload?.status?.value,
      fetchAttributes: true,
    };

    if (payload?.id) {
      mappedData.idIn = [payload?.id];
    }

    if (payload?.searchPath === ESearchPath.PathEquals && payload?.path) {
      mappedData.pathEquals = payload?.path;
    }

    if (payload?.searchPath === ESearchPath.PathPrefix && payload?.path) {
      mappedData.pathPrefix = payload?.path;
    }

    if (payload?.hasAdditionalAttribute.value !== EHasAdditionalAttribute.NotSelected) {
      mappedData.hasAttributes =
        payload?.hasAdditionalAttribute.value === EHasAdditionalAttribute.HasAttribute;
    }

    if (payload?.filterAttribute && !payload?.additionalAttribute) {
      mappedData.filterByAttributeKey = payload?.filterAttribute.value;
    }

    if (payload?.filterAttribute && payload?.additionalAttribute) {
      mappedData.attrs = {
        [payload.filterAttribute.value]: this._getDictionaryFilterAdditionalAttributeValue(
          payload.additionalAttribute,
          payload.additionalAttributeType
        ),
      };
    }

    const dictionaryList = await this.dictionariesService.fetchDictionariesByFilters(
      remoteName,
      mappedData
    );

    this.advancedFilterStore.setFetchedDictionaryList(dictionaryList);
    this.advancedFilterStore.setIsLoading(false);

    return dictionaryList;
  };

  markDictionaryAsLeader = (data: TDictionaryItem) => {
    const payload = {
      remoteName: this.dictionariesStore.selectedDictionaryListRemoteName.remoteName,
      id: data.id,
    };

    return this.dictionariesService.markDictionaryAsLeader(payload);
  };

  markDictionaryAsOriginal = (data: TDictionaryItem) => {
    const payload = {
      remoteName: this.dictionariesStore.selectedDictionaryListRemoteName.remoteName,
      id: data.id,
    };

    return this.dictionariesService.markDictionaryAsOriginal(payload);
  };

  copyFromDictionary = (data: TDictionaryItem) => {
    this.dictionariesStore.setDictionaryItemToCopy(data);
  };

  fetchAttributeListByOwnerId = (remoteName: string, ownerId: string) => {
    return this.dictionariesService.fetchAttributeListByOwnerId({
      remoteName,
      ownerId,
    });
  };

  fetchAttributesByParams = (payload: TypeApiRequest<'getAttributeListByOwnerId'>) => {
    return this.dictionariesService.fetchAttributeListByOwnerId(payload);
  };

  createDictionaryAttribute = (remoteName: string, attributeData: IAttributeItem) => {
    return this.dictionariesService.createDictionaryAttribute({ remoteName, ...attributeData });
  };

  getDictionaryDependenciesList = (payload: TypeApiRequest<'getDependenciesList'>) => {
    return this.dictionariesService.fetchDictionaryDependenciesList(payload);
  };

  getDictionaryDependenciesTreeRoot = async (payload: TypeApiRequest<'getDependenciesList'>) => {
    const mode: 'dependencyName' | 'dictionaryName' = payload.dependencyName
      ? 'dictionaryName'
      : 'dependencyName';

    const rootDependencies: IDictionaryDependencyWithAliasName[] = (
      await this.dictionariesService.fetchDictionaryDependenciesList(payload)
    ).content.map(dependency => {
      const name = this.dictionariesStore.dictionaryNameList.find(
        dictionary => dictionary.remoteName === dependency[mode]
      );

      return { ...dependency, name: name?.title || dependency[mode] };
    });

    return rootDependencies;
  };

  getAllDictionariesByParams = (
    remoteName: string,
    payload: TypeApiRequest<'getAllDictionariesByParams'>
  ) => {
    return this.dictionariesService.fetchDictionariesByFilters(remoteName, payload);
  };

  getLinkedDictionaries = (
    mode: 'depend' | 'dependent',
    remoteName: string,
    config: {
      dependencyName?: string;
      dependencyRecordId?: string;
      dependencyTargetName?: string;
      dependencyTargetPath?: string;
      parentId?: string;
    }
  ) => {
    const payload: TypeApiRequest<'getAllDictionariesByParams'> = {
      latestVersion: true,
      directDependencies: true,
      remoteName,
    };

    if (mode === 'dependent') {
      payload.dependencyName = config.dependencyName;
      payload.dependencyRecordId = config.dependencyRecordId;
    }

    if (mode === 'depend') {
      payload.dependencyTargetName = config.dependencyTargetName;
      payload.dependencyTargetPath = config.dependencyTargetPath;
    }

    if (config.parentId) {
      payload.parentId = config.parentId;
    }

    return this.getAllDictionariesByParams(remoteName, payload);
  };

  setSidebarDictionaryTree = (nodeList: string[]) => {
    this.treeStore.setPreOpenedTree(nodeList);
  };

  getCurrentDictionaryItemHistoryVersion = (version: number) => {
    return this.dictionariesStore.currentDictionaryItemHistory.get(version);
  };

  getDependencyLinksByDependencyRecord = (
    payload: TypeApiRequest<'getDependencyLinksByDependencyRecord'>
  ) => {
    return this.dictionariesService.getDependencyLinksByDependencyRecord(payload);
  };

  getDependencyLinksByTargetRecord = (
    payload: TypeApiRequest<'getDependencyLinksByTargetRecord'>
  ) => {
    return this.dictionariesService.getDependencyLinksByTargetRecord(payload);
  };

  /** Возвращает человекочитаемое имя справочника, если список справочников был загружен ранее */
  getDictionaryTitleByName = (remoteName: string) => {
    return (
      this.dictionariesStore.dictionaryNameList.find(item => item.remoteName === remoteName)
        ?.title || undefined
    );
  };

  createDependencyLink = (payload: TypeApiRequest<'createDependencyLink'>) => {
    return this.dictionariesService.createDependencyLink(payload);
  };

  createDependency = (payload: TypeApiRequest<'createDependency'>) => {
    return this.dictionariesService.createDependency(payload);
  };

  saveNewDependency = async (
    dictionaryName: string,
    dependencyName: string,
    newDependencyData: Omit<
      TypeApiRequest<'createDependencyLink'>,
      'dependencyRecordId' | 'dependencyId'
    >
  ) => {
    const dependencyId =
      (await this.getDictionaryDependenciesList({ dependencyName, dictionaryName })).content.find(
        item => item.dependencyName === dependencyName && item.dictionaryName === dictionaryName
      )?.id || null;

    if (dependencyId) {
      return this.createDependencyLink({ ...newDependencyData, dependencyId });
    } else {
      const newDependencyId = await this.createDependency({ dependencyName, dictionaryName });

      return this.createDependencyLink({ ...newDependencyData, dependencyId: newDependencyId });
    }
  };

  deleteDependencyLink = (dependencyId: string) => {
    return this.dictionariesService.deleteDependencyLink({ id: dependencyId });
  };

  cleanDictionaryCache = (remoteName: string) => {
    return this.dictionariesService.cleanDictionaryCache({ remoteName });
  };

  exportDictionary = (remoteName: string, options?: IDictionaryExportOptions) => {
    return this.dictionariesService.exportDictionary({ remoteName, ...options });
  };

  exportDictionaryDependencies = (remoteName: string, options?: IDictionaryExportOptions) => {
    return this.dictionariesService.exportDictionaryDependencies({ remoteName, ...options });
  };

  importDictionaryDryRun = (payload: TypeApiRequest<'importDictionaryDryRun'>, file: FormData) => {
    return this.dictionariesService.importDictionaryDryRun(payload, file);
  };

  importDictionary = (payload: TypeApiRequest<'importDictionary'>, file: FormData) => {
    return this.dictionariesService.importDictionary(payload, file);
  };

  importDictionaryDependenciesDryRun = (
    payload: TypeApiRequest<'importDictionaryDependenciesDryRun'>,
    file: FormData
  ) => {
    return this.dictionariesService.importDictionaryDependenciesDryRun(payload, file);
  };

  importDictionaryDependencies = (
    payload: TypeApiRequest<'importDictionaryDependencies'>,
    file: FormData
  ) => {
    return this.dictionariesService.importDictionaryDependencies(payload, file);
  };

  clearAndSetPreviousDictionaryItem = () => {
    this.dictionariesStore.setCurrentDictionaryItemPreviousVersion(
      this.dictionariesStore.currentDictionaryItem
    );

    this.dictionariesStore.clearCurrentDictionaryItem();
  };

  clearDictionaryItemDataForChild = () => {
    this.dictionariesStore.setCurrentDictionaryItemPreviousVersion(
      this.dictionariesStore.currentDictionaryItem
    );

    const newDictionaryItem = {
      ...this.dictionariesStore.currentDictionaryItem,
      attrs: {},
      validAttributes: new Map(),
    };

    this.dictionariesStore.setCurrentDictionaryItem(newDictionaryItem);
  };

  restorePreviousDictionaryItem = () => {
    this.dictionariesStore.setCurrentDictionaryItem(
      this.dictionariesStore.currentDictionaryItemPreviousVersion
    );
  };
}
