import Search from '@mui/icons-material/Search';
import { CircularProgress, IconButton } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, memo, useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { TDictionaryItem } from '../../../../../../api/models/dictionaries.model';
import { DictionariesController } from '../../../../../controllers/dictionaries.controller';
import { ERequestStatus } from '../../../../../shared/types/requests';
import { useStore } from '../../../../../shared/utils';
import { DictionariesStore, EFormModule, TreeStore } from '../../../../../stores';
import { AdminRoutes } from '../../../../routes';
import { DictionarySidebar as StyledDictionarySidebar } from '../../../../style';
import { AutocompleteSearchByNameOrId } from '../../common';

import { AdvancedFilter, TreeContainer } from './components';
import Styled from './styled';

enum ECurrentTab {
  Tree,
  AdvancedSearch,
}

const DictionarySidebar: FC = observer(() => {
  const { name: remoteName } = useParams<{ name: string }>();
  const { getShallowDictionaryListByRemoteName, changeFormModule } = useStore(
    DictionariesController
  );
  const { selectedDictionaryListRemoteName, activeDictionaryList, formModule } = useStore(
    DictionariesStore
  );
  const { setSelectedTreeNode } = useStore(TreeStore);

  const history = useHistory();

  const [requestStatus, setRequestStatus] = useState(ERequestStatus.Idle);
  const [currentTab, setCurrentTab] = useState<ECurrentTab>(ECurrentTab.Tree);

  useEffect(() => {
    if (!selectedDictionaryListRemoteName) {
      return;
    }

    setRequestStatus(ERequestStatus.Pending);
    getShallowDictionaryListByRemoteName(selectedDictionaryListRemoteName.remoteName)
      .then(() => {
        setRequestStatus(ERequestStatus.Fulfilled);
      })
      .catch(() => {
        setRequestStatus(ERequestStatus.Rejected);
      });
  }, [selectedDictionaryListRemoteName?.remoteName]);

  const handleSelectDictionaryItem = (item: TDictionaryItem) => {
    setSelectedTreeNode(item);
    if (item) {
      changeFormModule(EFormModule.Edit);

      if (item.level === -1) {
        history.push(
          generatePath(AdminRoutes.DictionaryName, {
            name: remoteName,
          })
        );
      } else {
        history.push(
          generatePath(AdminRoutes.DictionarySelectedItem, {
            itemId: item?.id,
            name: selectedDictionaryListRemoteName?.remoteName,
          })
        );
      }
    }
  };

  const dictionaryItemList = useMemo(() => {
    if (requestStatus !== ERequestStatus.Fulfilled || !selectedDictionaryListRemoteName)
      return null;

    return activeDictionaryList;
  }, [requestStatus, selectedDictionaryListRemoteName]);

  return (
    <>
      {selectedDictionaryListRemoteName?.remoteName && remoteName && (
        <StyledDictionarySidebar>
          <Styled.SidebarTab $display={currentTab === ECurrentTab.Tree}>
            <Styled.SearchWrapper>
              <AutocompleteSearchByNameOrId
                isDisplayId
                onChange={(e, value) => {
                  if (typeof value === 'object') {
                    handleSelectDictionaryItem(value.rawData);
                  }
                }}
              />
              <IconButton
                type="button"
                onClick={() => {
                  setCurrentTab(ECurrentTab.AdvancedSearch);
                }}
              >
                <Search />
              </IconButton>
            </Styled.SearchWrapper>
            {dictionaryItemList ? (
              <TreeContainer
                nodeList={dictionaryItemList}
                onSelectTreeNode={handleSelectDictionaryItem}
                remoteName={selectedDictionaryListRemoteName.remoteName}
              />
            ) : (
              <CircularProgress />
            )}
          </Styled.SidebarTab>
          <Styled.SidebarTab $display={currentTab === ECurrentTab.AdvancedSearch}>
            {currentTab === ECurrentTab.AdvancedSearch && (
              <AdvancedFilter
                goBackHandler={() => {
                  setCurrentTab(ECurrentTab.Tree);
                }}
              />
            )}
          </Styled.SidebarTab>
        </StyledDictionarySidebar>
      )}
    </>
  );
});

DictionarySidebar.displayName = 'DictionarySidebar';

export default memo(DictionarySidebar);
