import { IFieldReportConfig } from '../../../../models/reportConfig.model';
import { TypeResponsePageable } from '../../../../models/response.pageable.model';
import { TypeApiRoute } from '../../../../models/type.api.request';

type TypeRequest = {
  page?: number;
  size?: number;
  organizationId?: string;
  seasonYear?: number;
  enabled?: boolean;
};
type TypeResponse = TypeResponsePageable & { content: IFieldReportConfig[] };

export const getAssistanceFieldReportsConfig: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/da-reports/reports/field/config/list`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
