import _ from 'lodash';
import { TreeView } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Add from '@mui/icons-material/Add';
import { FC, useCallback, useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { generatePath, useHistory } from 'react-router-dom';

import { TDictionaryItem } from '../../../../../../../../api/models/dictionaries.model';
import { TreeNode } from '../TreeNode';
import { useStore } from '../../../../../../../shared/utils';
import { EFormModule, TreeStore } from '../../../../../../../stores';
import { DictionariesController } from '../../../../../../../controllers/dictionaries.controller';
import { AdminRoutes } from '../../../../../../routes';

import Styled from './styled';

interface ITreeContainerProps {
  nodeList: TDictionaryItem[];
  remoteName: string;
  onSelectTreeNode: (item: TDictionaryItem) => void;
}

export const TreeContainer: FC<ITreeContainerProps> = observer(
  ({ nodeList, onSelectTreeNode, remoteName }) => {
    const { setTreeMap, getTreeNode } = useStore(TreeStore);
    const {
      getDictionaryChildrenByParentId,
      changeFormModule,
      setParentId,
      clearAndSetPreviousDictionaryItem,
    } = useStore(DictionariesController);

    const history = useHistory();

    const [expandedList, setExpandedList] = useState<string[]>([]);
    const [currentId, setCurrentId] = useState<string>(null);

    const handleToggle = (event: any, nodeIds: string[]) => {
      setExpandedList(nodeIds);
    };

    const handleAddParent = useCallback(() => {
      setParentId(null);
      changeFormModule(EFormModule.Create);

      clearAndSetPreviousDictionaryItem();

      history.push(generatePath(AdminRoutes.DictionaryCreate, { name: remoteName }));
    }, []);

    useEffect(() => {
      const dataMap = new Map<string, TDictionaryItem[]>([
        [
          'root',
          [
            {
              code: remoteName,
              id: remoteName,
              name: `/${remoteName}`,
              level: -1,
              originalLink: generatePath(AdminRoutes.DictionaryName, {
                name: remoteName,
              }),
            },
            ...nodeList,
          ],
        ],
      ]);

      setTreeMap(dataMap);
    }, [nodeList]);

    const renderTreeNode = (dataNode: TDictionaryItem[]) =>
      dataNode?.map(node => (
        <TreeNode
          key={node.id}
          id={node.id}
          nodeData={node}
          remoteName={remoteName}
          getChildrenData={getDictionaryChildrenByParentId}
          onSelectItem={onSelectTreeNode}
          setCurrentId={setCurrentId}
          currentId={currentId}
          isSelected={node.id === currentId}
          isIndex={node?.level === -1}
        />
      ));

    return (
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expandedList}
        onNodeToggle={handleToggle}
      >
        <Styled.ActionGroup>
          <Button
            variant="text"
            onClick={() => {
              onSelectTreeNode(null);
              setExpandedList([]);
            }}
          >
            <Typography fontSize={12}>Свернуть всё</Typography>
          </Button>
          <Button onClick={handleAddParent} startIcon={<Add />} variant="contained">
            Добавить корень
          </Button>
        </Styled.ActionGroup>
        {nodeList && nodeList.length ? (
          renderTreeNode(getTreeNode('root'))
        ) : (
          <Typography>Элементы в справочнике отсутствуют</Typography>
        )}
      </TreeView>
    );
  }
);
