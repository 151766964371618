import { makeAutoObservable, runInAction } from 'mobx';

import * as attributeModel from '../../../api/models/attribute.model';
import { provide } from '../../shared/utils';

import {
  TDictionaryName,
  TDictionaryItem,
  TDictionaryItemCrumbs,
} from './../../../api/models/dictionaries.model';

export enum EDictionaryLoadingSteps {
  Idle,
  NameList,
  SelectedDictionary,
}

export enum EFormModule {
  Edit,
  Create,
  Copy,
}

export type TAttributeWithValue = attributeModel.IAttributeItem & {
  value?: string | number | boolean;
  isExist?: boolean;
};

export type TCurrentDictionaryExtended = TDictionaryItem & {
  validAttributes?: Map<string, TAttributeWithValue>;
  invalidAttribute?: Record<string, any>;
  attributeWIthoutValues?: TAttributeWithValue[];
};

@provide.singleton()
export class DictionariesStore {
  // Dictionary names. Dictionaries level: 0
  private _dictionaryNameList: TDictionaryName[] | null = null;
  private _remoteNameToDictionaryName: Map<
    TDictionaryName['remoteName'],
    TDictionaryName
  > = new Map();
  private _selectedDictionaryListRemoteName: TDictionaryName | null = null;
  private _currentDictionaryBreadcrumbs: TDictionaryItemCrumbs['crumbs'];

  // Dictionaries. Dictionaries level: 1
  private _remoteNameToDictList: Map<string, TDictionaryItem[]> = new Map();

  private _dictionaryLoadingStep: EDictionaryLoadingSteps;

  private _currentDictionaryItem: TCurrentDictionaryExtended | null = null;
  private _currentDictionaryItemPreviousVersion: TCurrentDictionaryExtended | null = null;

  private _formModule: EFormModule = null;
  private _parentId: string = null;
  private _dictionaryItemToCopy: TDictionaryItem = null;
  private _newAttributeIdToAttributeItem: Map<string, attributeModel.IAttributeItem> = new Map();
  private _currentDictionaryItemHistory: Map<number, TDictionaryItem> = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Верхнеуровневые имена справочников
   *
   * @readonly
   * @memberof DictionariesStore
   */
  get dictionaryNameList() {
    return this._dictionaryNameList;
  }

  get selectedDictionaryListRemoteName() {
    return this._selectedDictionaryListRemoteName;
  }

  get activeDictionaryList(): TDictionaryItem[] | null {
    return (
      this._remoteNameToDictList.get(this._selectedDictionaryListRemoteName?.remoteName) || null
    );
  }

  get dictionaryLoadingStep() {
    return this._dictionaryLoadingStep;
  }

  get currentDictionaryItem() {
    return this._currentDictionaryItem;
  }

  get currentDictionaryItemPreviousVersion() {
    return this._currentDictionaryItemPreviousVersion;
  }

  setCurrentDictionaryItemPreviousVersion = (item: TCurrentDictionaryExtended) => {
    this._currentDictionaryItemPreviousVersion = item;
  };

  getDictionaryNameByRemoteName = (remoteName: string): TDictionaryName | null => {
    return this._remoteNameToDictionaryName.get(remoteName) || null;
  };

  /**
   * Справочники полученные от имени верхнеуровневого справочника
   *
   * @param {string} remoteName
   * @return {*}  {(TDictionaryItem[] | null)}
   * @memberof DictionariesStore
   */
  getDictListByRemoteName = (remoteName: string): TDictionaryItem[] | null => {
    return this._remoteNameToDictList.get(remoteName) || null;
  };

  get formModule() {
    return this._formModule;
  }

  get parentId() {
    return this._parentId;
  }

  get dictionaryItemToCopy() {
    return this._dictionaryItemToCopy;
  }

  get currentDictionaryItemHistory() {
    return this._currentDictionaryItemHistory;
  }

  get newAttributeMap() {
    return this._newAttributeIdToAttributeItem;
  }

  getAttributeItemByAttributeId = (id: string) => {
    return this._newAttributeIdToAttributeItem.get(id);
  };

  get currentDictionaryItemBreadcrumbs() {
    return this._currentDictionaryBreadcrumbs;
  }

  removeNewAttributeItemFormAttributeList = (id: string) => {
    this._newAttributeIdToAttributeItem.delete(id);
  };

  changeNewAttributeItemFromAttributeList(id: string, changeItem: attributeModel.IAttributeItem) {
    const changedMap = this._newAttributeIdToAttributeItem;
    this._newAttributeIdToAttributeItem.set(id, changeItem);
  }

  setDictionaryNameList(dictionaryNameList: TDictionaryName[]) {
    runInAction(() => {
      this._dictionaryNameList = dictionaryNameList;
    });
  }

  setRemoteNameToDictionaryName = (dictionaryNameList: TDictionaryName[]) => {
    runInAction(() => {
      dictionaryNameList.forEach(dictName => {
        this._remoteNameToDictionaryName.set(dictName.remoteName, dictName);
      });
    });
  };

  setSelectedDictionaryListRemoteName = (dictionaryName: TDictionaryName) => {
    runInAction(() => {
      this._selectedDictionaryListRemoteName = dictionaryName;
    });
  };

  setDictListByRemoteName = (remoteName, dictionaryItemList: TDictionaryItem[]) => {
    this._remoteNameToDictList.set(remoteName, dictionaryItemList);
  };

  setDictionaryLoadingStep = (step: EDictionaryLoadingSteps) => {
    this._dictionaryLoadingStep = step;
  };

  setCurrentDictionaryItem = (item: TCurrentDictionaryExtended) => {
    this._currentDictionaryItem = item;
  };

  setFormModule = (moduleType: EFormModule) => {
    this._formModule = moduleType;
  };

  setParentId = (id: string) => {
    this._parentId = id;
  };

  setDictionaryItemToCopy = (dictionaryItem: TCurrentDictionaryExtended) => {
    this._dictionaryItemToCopy = dictionaryItem;
  };

  setCurrentDictionaryItemHistory = (
    dictionaryHistoryMap: typeof this._currentDictionaryItemHistory
  ) => {
    this._currentDictionaryItemHistory = dictionaryHistoryMap;
  };

  setCurrentDictionaryItemBreadCrumbs = (crumbs: TDictionaryItemCrumbs['crumbs']) => {
    this._currentDictionaryBreadcrumbs = crumbs;
  };

  setNewAttributeToNewAttributeMap = (id: string, newAttribute: attributeModel.IAttributeItem) => {
    this._newAttributeIdToAttributeItem.set(id, newAttribute);
  };

  clearSelectedDictionary = () => {
    this._selectedDictionaryListRemoteName = null;
  };

  clearCurrentDictionaryItem = () => {
    this._currentDictionaryItem = null;
  };

  clearNewAttributeMap = () => {
    this._newAttributeIdToAttributeItem = new Map();
  };

  clearDictionariesStore = () => {
    this._dictionaryNameList = null;
    this.clearCurrentDictionaryItem();
    this._currentDictionaryItemPreviousVersion = null;
  };
}
