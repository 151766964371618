import { observer } from 'mobx-react';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Divider from '@mui/material/Divider';
import { toJS } from 'mobx';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import { TDictionaryItem } from '../../../../../../../../../../../../../../api/models/dictionaries.model';
import {
  DictionariesStore,
  TAttributeWithValue,
} from '../../../../../../../../../../../../../stores';
import { useStore } from '../../../../../../../../../../../../../shared/utils';
import { DictionariesController } from '../../../../../../../../../../../../../controllers/dictionaries.controller';
import { AdminRoutes } from '../../../../../../../../../../../../routes';

import { AddParam, ParamList } from './components';
import Styled from './styled';

const AdditionalParams = observer(
  ({
    isIndexDictionary,
    isCreateChild,
  }: {
    isIndexDictionary?: boolean;
    isCreateChild?: boolean;
  }) => {
    const { name, itemId } = useParams<{ name: string; itemId: string }>();
    const location = useLocation();
    const isCreateMode = Boolean(
      matchPath(location.pathname, {
        path: AdminRoutes.DictionaryCreate,
        exact: true,
        strict: false,
      })
    );

    const {
      setNewAttributeItemByAttributeId,
      removeNewAttributeItemFormAttributeList,
      changeNewAttributeItem,
      getNewAttributeItemByAttributeId,
      createDictionaryAttribute,
      updateCurrentDictionaryItem,
      changeCurrentDictionaryHandler,
      fetchAttributesByParams,
    } = useStore(DictionariesController);

    const { newAttributeMap, currentDictionaryItem, clearNewAttributeMap } = useStore(
      DictionariesStore
    );

    const [validAttributeList, setValidAttributeList] = useState<{
      [k: string]: TAttributeWithValue;
    } | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      return () => clearNewAttributeMap();
    }, []);

    useEffect(() => {
      if (isCreateMode || isIndexDictionary || isCreateChild) {
        setIsLoading(true);

        fetchAttributesByParams({
          remoteName: name,
          ownerPath: isCreateMode ? '/' : currentDictionaryItem?.path || '/',
        })
          .then(data => {
            const attributesObj = {};

            data.forEach(item => {
              attributesObj[item.code] = { ...item, value: null };
            });

            setValidAttributeList(attributesObj);
          })
          .finally(() => {
            setIsLoading(false);
          });
        return;
      }

      if (
        !(isCreateMode || isIndexDictionary || isCreateChild) &&
        currentDictionaryItem?.validAttributes
      ) {
        setValidAttributeList(Object.fromEntries(currentDictionaryItem?.validAttributes));
      }
    }, [isIndexDictionary, isCreateMode, isCreateChild, currentDictionaryItem?.id]);

    const handleChangeAttribute = useCallback(
      (attributeValue: TDictionaryItem['attrs'], key: string, removeValue?: boolean) => {
        changeCurrentDictionaryHandler(attributeValue, key, removeValue);
      },
      [toJS(currentDictionaryItem)?.validAttributes, toJS(currentDictionaryItem)?.attrs]
    );

    const handleSaveNewAttribute = useCallback(
      (id: string) => {
        const attribute = getNewAttributeItemByAttributeId(id);

        if (!attribute.ownerId) {
          attribute.ownerId = itemId;
        }

        if (isIndexDictionary) {
          attribute.ownerPath = '/';
        }

        return createDictionaryAttribute(name, attribute).then(() => {
          if (!isIndexDictionary) {
            const updatedMap = toJS(currentDictionaryItem)?.validAttributes;

            updatedMap.set(attribute.code, {
              ...attribute,
              value: null,
            });

            updateCurrentDictionaryItem({ validAttributes: updatedMap });
          }

          setValidAttributeList({
            ...validAttributeList,
            [attribute.code]: { ...attribute, value: null },
          });

          removeNewAttributeItemFormAttributeList(id);
        });
      },
      [toJS(currentDictionaryItem), validAttributeList, isIndexDictionary]
    );

    if (isLoading) {
      return <CircularProgress />;
    }

    return (
      <Styled.TabWrapper>
        <AddParam
          newAttributesMap={toJS(newAttributeMap)}
          setNewAttributeValue={setNewAttributeItemByAttributeId}
          changeAttribute={changeNewAttributeItem}
          removeNewAttribute={removeNewAttributeItemFormAttributeList}
          saveAttribute={handleSaveNewAttribute}
          isIndexDictionary={isIndexDictionary}
        />
        <Divider />
        {
          <ParamList
            validAttributeList={validAttributeList}
            invalidAttributeList={currentDictionaryItem?.invalidAttribute}
            attributeValueChangeHandler={handleChangeAttribute}
            isIndexDictionary={isIndexDictionary}
            isCreateMode={isCreateMode}
          />
        }
      </Styled.TabWrapper>
    );
  }
);

export default memo(AdditionalParams);
