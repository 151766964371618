import { Button, FormControlLabel, Snackbar, Switch } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { FC, memo, useCallback, useState } from 'react';
import * as yup from 'yup';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import {
  EDictionaryItemStatus,
  TDictionaryItem,
} from '../../../../../../../../../../api/models/dictionaries.model';
import { useStore } from '../../../../../../../../../shared/utils';
import { DropdownButton } from '../../../../../../../../../shared/components';
import { DictionariesController } from '../../../../../../../../../controllers/dictionaries.controller';
import { EFormModule, TCurrentDictionaryExtended } from '../../../../../../../../../stores';
import { AdminRoutes } from '../../../../../../../../routes';

import { FormTabs, SelectNameOrId } from './components';
import Styled from './styled';

enum EFormFields {
  Id = 'id',
  Path = 'path',
  ParentId = 'parentId',
  OriginalLink = 'originalLink',
  Name = 'name',
  Description = 'description',
  AuthorFullName = 'authorFullName',
  EditDate = 'editDate',
  Status = 'status',
  Original = 'original',
  IsChild = 'isChild',
}

const DictForm: FC<{
  onSubmit: (any) => void;
  data: TCurrentDictionaryExtended;
  isIndex?: boolean;
}> = ({ onSubmit, data, isIndex }) => {
  const { name: remoteName, itemId, version } = useParams<{
    name: string;
    itemId: string;
    version: string;
  }>();
  const history = useHistory();
  const {
    updateDictionaryData,
    rewriteDictionaryData,
    activateDictionaryData,
    archiveDictionaryItem,
    deleteDictionaryItem,
    changeFormModule,
    setParentId,
    getCurrentDictionaryItemHistoryVersion,
  } = useStore(DictionariesController);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [tempData, setTempData] = useState<Partial<TCurrentDictionaryExtended>>({
    ...data,
    isChild: Boolean(data?.parentId),
    isDouble: data?.original,
  });

  const schema = yup.object().shape({
    [EFormFields.IsChild]: yup.boolean(),
    [EFormFields.ParentId]: yup.string().when([EFormFields.IsChild], {
      is: true,
      then: yup.string().required('Укажите дочерний элемент'),
    }),
    [EFormFields.Original]: yup.boolean(),
    [EFormFields.OriginalLink]: yup.string().when([EFormFields.Original], {
      is: true,
      then: yup.string().required('Укажите дочерний элемент'),
    }),
  });

  const onFormChange = (e: React.FormEvent<HTMLFormElement>) => {
    const target = e.target as HTMLInputElement;

    setTempData({
      ...tempData,
      ...{ [target.name]: target.type === 'checkbox' ? target.checked : target.value },
    });
  };

  const handleActiveItem = useCallback(
    (value: TDictionaryItem) => {
      activateDictionaryData(value)
        .then(() => {
          setSnackbarMessage('Запись активна');
          setShowSnackbar(true);
          setTempData({
            ...tempData,
            status: EDictionaryItemStatus.Active,
          });
        })
        .catch(() => {
          setSnackbarMessage('Ошибка активации записи');
          setShowSnackbar(true);
        });
    },
    [tempData]
  );

  const handleArchiveItem = useCallback(
    (value: TDictionaryItem) => {
      archiveDictionaryItem(value)
        .then(() => {
          setSnackbarMessage('Запись оправлена в архив');
          setShowSnackbar(true);
          setTempData({
            ...tempData,
            status: EDictionaryItemStatus.Inactive,
          });
        })
        .catch(() => {
          setSnackbarMessage('Ошибка отправления в архив');
          setShowSnackbar(true);
        });
    },
    [tempData]
  );

  const handleDeleteItem = useCallback((value: TDictionaryItem) => {
    deleteDictionaryItem(value)
      .then(() => {
        setSnackbarMessage('Запись удалена');
        setShowSnackbar(true);
      })
      .catch(() => {
        setSnackbarMessage('Ошибка удаления записи');
        setShowSnackbar(true);
      });
  }, []);

  const handleAddChild = useCallback((value: TDictionaryItem) => {
    setParentId(value);
    changeFormModule(EFormModule.Create);
  }, []);

  const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  const onSaveHandler = (title: string) => {
    setSnackbarMessage(title);
    setShowSnackbar(true);
  };

  const handleGoToLatest = () => {
    history.goBack();
  };

  if (isIndex) {
    return <FormTabs />;
  }

  return (
    <>
      <Formik
        initialValues={tempData}
        onSubmit={onSubmit}
        validationSchema={schema}
        enableReinitialize
      >
        {({ values, errors, setFieldValue }) => (
          <Form onChange={onFormChange}>
            <Styled.ActionsRow>
              <Styled.ActionRowGroup>
                {version ? (
                  <>
                    <Button
                      onClick={() =>
                        updateDictionaryData(
                          getCurrentDictionaryItemHistoryVersion(Number(version)),
                          true
                        ).then(() => {
                          onSaveHandler(`Восстановлена версия ${version}`);
                          history.push(
                            generatePath(AdminRoutes.DictionarySelectedItem, {
                              itemId,
                              name: remoteName,
                            })
                          );
                        })
                      }
                      variant="contained"
                    >
                      Восстановить эту версию
                    </Button>
                    <Button onClick={handleGoToLatest}>Вернуться</Button>
                  </>
                ) : (
                  <>
                    <DropdownButton
                      title="Сохранить"
                      optionList={[
                        {
                          title: 'Сохранить с историей',
                          handler: () =>
                            updateDictionaryData(values).then(() =>
                              onSaveHandler(
                                `Запись ${values?.name ? `'${values.name}'` : ''} сохранена`
                              )
                            ),
                          color: 'success',
                        },
                        {
                          title: 'Перезаписать',
                          handler: () =>
                            rewriteDictionaryData(values).then(() =>
                              onSaveHandler(
                                `Запись ${values?.name ? `'${values.name}'` : ''} перезаписана`
                              )
                            ),
                        },
                      ]}
                      variant="contained"
                    />
                    <Button onClick={() => handleAddChild(values)} variant="contained">
                      Добавить дочернюю
                    </Button>
                  </>
                )}
              </Styled.ActionRowGroup>
              <Styled.ActionRowGroup>
                {tempData.status === EDictionaryItemStatus.Inactive && (
                  <Button onClick={() => handleActiveItem(values)} variant="contained">
                    Вернуть из архива
                  </Button>
                )}
                <DropdownButton
                  title="Удалить"
                  optionList={[
                    {
                      title: 'В архив',
                      handler: () => handleArchiveItem(values),
                      color: 'error',
                    },
                    {
                      title: 'Удалить',
                      handler: () => handleDeleteItem(values),
                      color: 'error',
                    },
                  ]}
                  variant="contained"
                  color="error"
                />
              </Styled.ActionRowGroup>
            </Styled.ActionsRow>

            <Styled.FormRow>
              <Field
                component={TextField}
                name="id"
                value={tempData?.id}
                label="ID"
                fullWidth
                readOnly
                disabled
              />
              <Field
                component={TextField}
                name="path"
                value={tempData.path}
                label="Path"
                fullWidth
                readOnly
                disabled
              />
            </Styled.FormRow>
            <Styled.FormRow>
              <Field
                component={() => (
                  <FormControlLabel
                    control={<Switch id="isChild" />}
                    label="Является дочерней от"
                    sx={{ width: '60%' }}
                    checked={tempData?.isChild}
                    name="isChild"
                  />
                )}
                defaultChecked={Boolean(tempData?.parentId)}
                name="isChild"
                type="checkbox"
                fullWidth
              />
              <SelectNameOrId
                name="parentId"
                label="parentId"
                isDisabled={!tempData?.isChild}
                value={tempData.parentId}
                error={errors[EFormFields.ParentId] && String(errors[EFormFields.ParentId])}
                setFieldValue={setFieldValue}
              />
            </Styled.FormRow>
            <Styled.FormRow>
              <Field
                component={() => (
                  <FormControlLabel
                    control={<Switch id="original" />}
                    label="Является дубликатом записи"
                    sx={{ width: '60%' }}
                    checked={tempData?.original}
                    name="original"
                  />
                )}
                defaultChecked={Boolean(tempData?.original)}
                name="original"
                type="checkbox"
                fullWidth
              />
              <SelectNameOrId
                name="originalLink"
                label="originalLink"
                isDisabled={!tempData?.original}
                value={tempData.originalLink}
                error={errors[EFormFields.OriginalLink] && String(errors[EFormFields.OriginalLink])}
                setFieldValue={setFieldValue}
              />
            </Styled.FormRow>
            <Styled.FormRow>
              <Field
                component={TextField}
                name="code"
                value={tempData?.code}
                label="Код"
                fullWidth
              />
              <Field
                component={TextField}
                name="name"
                value={tempData?.name}
                label="Наименование"
                fullWidth
              />
            </Styled.FormRow>
            <Styled.FormRow>
              <Field
                component={TextField}
                name="description"
                value={tempData?.description}
                label="Описание"
                fullWidth
                rows={3}
              />
            </Styled.FormRow>
            <Styled.FormRow>
              <Field
                component={TextField}
                name="authorFullName"
                value={tempData?.authorFullName}
                label="Автор"
                fullWidth
                readOnly
                disabled
              />
              <Field
                component={TextField}
                name="editDate"
                value={tempData.editDate}
                label="Дата изменения"
                fullWidth
                readOnly
                disabled
              />
              <Field
                component={TextField}
                name="status"
                value={tempData.status}
                label="Статус"
                fullWidth
                readOnly
                disabled
              />
            </Styled.FormRow>
            <FormTabs />
          </Form>
        )}
      </Formik>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </>
  );
};

export default memo(DictForm);
