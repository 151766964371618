import {
  EImportOperationStatus,
  IAssistanceFieldsOperationHistoryModel,
} from '../../models/importOperation.model';
import { TypeResponsePageable } from '../../models/response.pageable.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  size: number;
  page: number;
  configId: string;
} & Partial<{
  organizationId: string;
  fromDate: string;
  toDate: string;
  statusIn: EImportOperationStatus[];
}>;
type TypeResponse = TypeResponsePageable & { content: IAssistanceFieldsOperationHistoryModel[] };

export const getReportAssistanceFieldsImportOperationList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/da-reports/reports/field/operations/list`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
