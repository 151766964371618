import { IFieldReportConfig } from '../../../../models/reportConfig.model';
import { TypeApiRoute } from '../../../../models/type.api.request';

type TypeRequest = {
  id: string;
};
type TypeResponse = IFieldReportConfig;

export const disableAssistanceFieldReportsAutoUpload: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (params: TypeRequest) => `/api/da-reports/reports/field/config/${params.id}/disable`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
